const MovieList = (props) => {
  return (
    <div className="row">
      {props.moviesList.map((movie, index) => {
        return (
          <label className="text-white-50" key={index}>
            {movie}
          </label>
        );
      })}
    </div>
  );
};

export default MovieList;
