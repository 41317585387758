import { useState } from "react";
const Counter = () => {
  const [counter, setCount] = useState(() => {
    return 0;
  });

  const [title, setTitle] = useState(() => {
    return "Fun";
  });

  function incrementCounter() {
    return setCount(counter + 1);
  }

  function decrementCounter() {
    return setCount(counter - 1);
  }

  function setNewTitle(newTitle) {
    return setTitle(newTitle);
  }

  return (
    <div className="col-12 col-md-4 offset-md-4 border text-white">
      <input
        type="text"
        className="form-control"
        placeholder="Enter Title"
        name="title"
        onChange={(e) => setNewTitle(e.target.value)}
      ></input>
      <span className="h2 pt-4 m-2 text-white-50">{title} Counter</span>
      <br />
      <button className="btn btn-success m-2" onClick={incrementCounter}>
        +1
      </button>
      <button className="btn btn-danger m-2" onClick={decrementCounter}>
        -1
      </button>
      <br />
      <span className="h4">
        Counter: &nbsp;
        <span className="text-success">{counter}</span>
      </span>
    </div>
  );
};

export default Counter;
