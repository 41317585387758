import { useState } from "react";
import AddMovie from "./AddMovie";
import MovieList from "./MovieList";

const MoviePage = () => {
  const [movieState, setMovies] = useState(() => {
    return { moviesList: ["Die Hard", "Harry Potter"] };
  });

  function handleAddMovie(movie) {
    setMovies((prevMovies) => {
      return {
        ...prevMovies,
        moviesList: prevMovies.moviesList.concat([movie]),
      };
    });
  }

  return (
    <div className="container col-12 col-md-6 border my-3 text-white">
      <AddMovie handleAddMovie={handleAddMovie} />
      <MovieList moviesList={movieState.moviesList} />
    </div>
  );
};

export default MoviePage;
