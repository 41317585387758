const Footer = () => {
  return (
    <div className="footer fixed-bottom">
      <p
        style={{
          color: "gray",
          borderTop: "1px solid #777",
          marginTop: "10px",
          textAlign: "center",
        }}
      >
        Happy Coding @WatchOpedia!
      </p>
    </div>
  );
};
export default Footer;
